import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { EventsPageQuery } from "../../graphql/types"
import { useIndexPageData } from "../hooks/use-index-page-data"
import {
  mapSourceUpcomingEventsToView,
  mapSourceUpcomingEventToView
} from "../features/home-page/mappers/upcoming-events-mapper"
import { EventsListPage } from "../features/events/components/events-list-page/events-list-page"
import { PageProps } from "../types/page"
import { Page } from "../components/page/page"

export const Events: FunctionComponent<PageProps<EventsPageQuery>> = ({
  data,
  ...props
}) => {
  const { header, metadata } = useIndexPageData("events")
  const homePageData = data!.contentfulHomePage!

  const events = mapSourceUpcomingEventsToView(data?.allEvent.nodes)
  const featuredEvent = mapSourceUpcomingEventToView(
    homePageData?.featuredEvent
  )
  const displayFeaturedEvent = Boolean(homePageData?.displayFeaturedEvent)

  return (
    <Page
      {...props}
      metadata={metadata}
      pageOptions={{ headerVariant: "cream" }}
    >
      <EventsListPage
        pageHeader={header}
        events={events}
        featuredEvent={featuredEvent}
        displayFeaturedEvent={displayFeaturedEvent}
      />
    </Page>
  )
}

export default Events

export const query = graphql`
  query EventsPage {
    contentfulHomePage(title: { eq: "Home Page" }) {
      featuredEvent {
        ...Event
      }
      displayFeaturedEvent
    }
    allEvent(sort: { fields: [startDate], order: ASC }) {
      nodes {
        ...EventNode
      }
    }
  }
`
