import React, { FunctionComponent, Fragment } from "react"
import { IndexPageHeader } from "../../../../types/page"
import { IndexHeader } from "../../../../components/index-header/index-header"
import { EventsList } from "../../../../components/events-list/events-list"
import { Event } from "../../event-types"

export interface EventsListPageProps {
  pageHeader: IndexPageHeader
  events: Event[] | null
  featuredEvent?: Event | null
  displayFeaturedEvent?: boolean
}

export const EventsListPage: FunctionComponent<EventsListPageProps> = ({
  pageHeader,
  events,
  featuredEvent,
  displayFeaturedEvent
}) => (
  <Fragment>
    <IndexHeader header={pageHeader} />
    {events && (
      <EventsList
        featuredEvent={featuredEvent}
        displayFeaturedEvent={displayFeaturedEvent}
        list={events}
        variant="secondary"
        fullList
      />
    )}
  </Fragment>
)
