import { FluidObject } from "gatsby-image"
import {
  EventFragment,
  ContentfulTeacher,
  Maybe
} from "../../../../graphql/types"
import { mapTeachers } from "../../../core/mappers/map-teachers"
import { Event } from "../../events/event-types"
import { formatDatePeriod } from "../../../i18n/dates"

export const eventMapper = (node: Maybe<EventFragment> | null | undefined) => {
  const speakers = node!.speakers
    ? mapTeachers(node!.speakers as Partial<ContentfulTeacher>[])
    : []

  return {
    title: node!.title!,
    location: node!.location || "",
    type: node!.type!,
    date: formatDatePeriod(node!.startDate!, node!.endDate || ""),
    url: node!.url || "",
    image: node!.image?.fluid?.src || "",
    imageFluid: node?.image?.fluid as FluidObject,
    speakers
  }
}

export const mapSourceUpcomingEventToView = (
  node: Maybe<EventFragment> | null | undefined
) => {
  if (!node) return null

  return eventMapper(node)
}

export const mapSourceUpcomingEventsToView = (
  nodes: Maybe<EventFragment>[] | null | undefined
): Event[] | null => {
  if (!nodes) {
    return null
  }

  return nodes.map((node) => {
    return eventMapper(node)
  })
}
